<template>
  <div class="topic bg-white rtl">
    <van-nav-bar
      title="دارا ساباقحاناسى "
      @click-left="$router.back()"
      @click-right="$router.push('/')"
      fixed
    >
      <div slot="left" style="color: #fff; font-size: 16px">
        قايتۋ
        <van-icon name="arrow-left" size="16px" />
      </div>
      <div slot="right" style="color: #fff; font-size: 16px">
        <van-icon name="wap-home-o" size="18px" />
        باس بەت
      </div>
    </van-nav-bar>
    <!-- 加载中 -->
    <div v-if="fistLoading" class="loading-wrap">
      <van-loading color="#3296fa" vertical>مازمۇن ىزدەلۋدە...</van-loading>
    </div>
    <template v-if="!fistLoading">
      <div class="top_img">
        <van-image
          round
          class="avatar"
          fit="cover"
          :src="
            teacherDetail.avatar
              ? teacherDetail.avatar
              : '../../assets/avatar.jpg'
          "
        />
        <h4 class="teacherName">{{ teacherDetail.title }}</h4>
        <p class="teacherDesc">{{ teacherDetail.intro }}</p>
      </div>
      <van-divider
        :style="{ color: '#999', borderColor: '#999', padding: '0 16px' }"
        >مازمۇندار</van-divider
      >
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="isEmpty ? 'قازىرشە مازمۇن جوق' : ''"
        :error.sync="error"
        error-text="مازمۇن ىزدەلۋ ۇستىندە، بۇل جەردى باسىڭىز"
        @load="onLoad"
        loading-text="ىزدەلۋدە"
      >
        <CourseItem :hots="courses" />
      </van-list>
      <van-empty v-show="isEmpty" />
    </template>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import { initShareInfo } from '@/utils/util'
import CourseItem from '@/components/Course-item'
import { teacherDetail, topicOrTeacherCourse } from '@/api/topic'
export default {
  data () {
    return {
      courses: [], // 课程列表数据
      pages: {
        cateId: null,
        pagenum: 1,
        pagesize: 9,
        total_pages: null,
        total: null,
        filmName: ''
      },
      loading: false, // 上拉加载更多的 loading 状态
      finished: false, // 是否加载结束
      error: false, // 是否加载失败
      teacherDetail: {},
      fistLoading: true
    }
  },
  components: {
    CourseItem
  },
  mounted () {
    this.queryTeacherDetail()
  },
  computed: {
    isEmpty () {
      return this.finished && !this.pages.total && !this.courses.length
    }
  },
  methods: {
    // 监听底部事件
    async onLoad () {
      try {
        // 1. 请求获取数据
        const { data } = await topicOrTeacherCourse({
          ...this.pages,
          teacher_id: this.$route.params.teacher_id
        })

        // 2. 把数据添加到 courses 数组中
        const results = data.data
        this.courses.push(...results)
        this.pages.total = data.meta.total

        // 3. 设置本次加载中 loading 状态结束
        this.loading = false
        const { pagenum, pagesize } = this.pages
        // 4. 判断数据是否加载结束
        if (pagenum * pagesize >= data.meta.total) {
          // 没有数据了，设置加载状态结束，不再触发上拉加载更多了
          this.finished = true
        } else {
          // 更新获取下一页数据的时间戳
          this.pages.pagenum = pagenum + 1
        }
      } catch (err) {
        console.log(err)
        this.loading = false // 关闭 loading 效果
        this.error = true // 开启错误提示
      }
    },
    async queryTeacherDetail () {
      try {
        this.fistLoading = true
        const { data } = await teacherDetail(this.$route.params.teacher_id)
        this.teacherDetail = data
        wx.ready(() => {
          initShareInfo({
            title: `${data.title} - دارا ساباقحاناسى `, // 分享标题
            imgUrl: data.avatar // 分享图标
          })
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.fistLoading = false // 关闭 loading 效果
      }
    }
  }
}
</script>

<style lang="less" scoped>
.topic {
  height: 100vh;
  padding-top: 92px;
  .top_img {
    position: relative;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding-top: 50px;
    // margin: 20px;
    border-radius: 6px;

    .avatar {
      width: 120px;
      height: 120px;
      border: 6px solid #fff;
      border-radius: 50% !important;
    }

    .teacherName {
      position: relative;
      font-size: 30px;
      color: #333;
      text-align: center;
      margin: 20px 0 30px;
      z-index: 999;
    }

    .teacherDesc {
      position: relative;
      font-size: 24px;
      color: #999;
      text-align: justify;
      padding: 0 30px;
      line-height: 1.3;
    }
  }

   /deep/ .article-item {
     padding: 14px;
   }
}
</style>
